@keyframes slideshow {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.desktop-only {
  @media screen and (max-width: 1244px) {
    display: none;
  }
}