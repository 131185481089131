.table-container {
  background-color: #fcfcfc; //f3f4f6
  height: 30rem;
}

.table-text {
  font-size: 1rem !important;
  color: #4d4d4d !important;
  letter-spacing: 0.05rem !important;
  font-family: "Montserrat", sans-serif !important;
  border-bottom: 2px solid #eaeaea !important;
}

.table-title {
  font-weight: bold !important;
}

.table-row {
  line-height: 2rem;
}
